div.Layout {
  display: grid;
  height: 100vh;
  grid-template-areas:
    "header header header header"
    "body body body body"
    "footer footer footer footer";
}

div.Layout > section {
  background: white;
}

section.Layout__Header {
  grid-area: header;
  position: sticky;
  top: 0;
}

section.Layout__Header > header {
  display: block;
  /* justify-content: space-between; */
  /* align-items: center; */
  height: 100%;
  /* width: 95%; */
  margin: 0 auto;
}

section.Layout__Body {
  grid-area: body;
  overflow-y: scroll;
}

section.Layout__Footer {
  grid-area: footer;
  bottom: 0;
  position: fixed;
  width: 100%;
}

section.Layout__Body > div {
  margin: 20px auto;
  width: 95%;
}
