:root {
  /*DECLARAR VARIAVEIS: atalhos para as cores principais do projeto */
  --azul: #5176c7; /*AINDA NAO DEFINIDO*/
  --branco: rgb(255, 255, 255);
  --azul_ecm: rgb(0, 130, 199); /*#0082C7*/
  --amarelo_ecm: rgb(239, 174, 51); /*#EFAE33*/
  --background_ecm: rgb(26, 34, 53); /*#1A2235*/
}

* {
  /*reset de todos os estilos (para inicio de projeto)*/
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
header {
  font-family: "Hind Siliguri", sans-serif;
  font-size: 24px;
  font-weight: 400;
  overflow: hidden;
}

h3 {
  color: var(--background_ecm);
  font-weight: 600;
}

.empresa_text {
  padding: 0 200px 0 200px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  header .container {
    display: flex;
    flex-direction: column;
  }

  .empresa_text {
    padding: 0;
    text-align: center;
  }

  .botao {
    margin-top: 5px;
    width: 190px;
  }
}

@media screen and (min-width: 1000px) {
  .descricao {
    width: 50%;
  }
}
